import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeSidebar } from '../../actions';
import { Link } from 'react-router-dom';

class ProductsSidebar extends Component {
    state = {}
    handleItemClick = (e) => {
        this.props.closeSidebar();
    }

    render() {
        const { activeItem } = this.state

        return (
            <React.Fragment>
                <Menu.Item>
                    <Menu.Header>Mantili</Menu.Header>

                    <Menu.Menu>
                        <Link to="/shop/Strukirani_jednobojni_mantil" onClick={this.handleItemClick} className='item'> Strukirani jednobojni mantil </Link>
                        <Link to="/shop/Mantil_u_tri_boje" onClick={this.handleItemClick} className='item'> Mantil u tri boje </Link>
                    </Menu.Menu>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header>Odela</Menu.Header>

                    <Menu.Menu>
                        {/* <Link to="/shop/Jesenje_dnevno_odelo" onClick={this.handleItemClick} className='item'> Jesenje dnevno odelo </Link> */}
                        <Link to="/shop/Dnevno_odelo" onClick={this.handleItemClick} className='item'> Dnevno odelo </Link>
                        <Link to="/shop/New_York_style_odelo" onClick={this.handleItemClick} className='item'> New York style odelo </Link>
                    </Menu.Menu>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header> Trenerke </Menu.Header>

                    <Menu.Menu>
                        <Link to="/shop/Tema_trenerka" onClick={this.handleItemClick} className='item'> Tema trenerka </Link>
                    </Menu.Menu>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header> Košulje </Menu.Header>

                    <Menu.Menu>
                        <Link to="/shop/Oversized_košulja" onClick={this.handleItemClick} className='item'> Oversized košulja </Link>
                    </Menu.Menu>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header>Elegantne haljine</Menu.Header>

                    <Menu.Menu>
                        <Link to="/shop/Elegantna_haljina_sa_mašnama" onClick={this.handleItemClick} className='item'> Elegantna haljina sa mašnama </Link>
                    </Menu.Menu>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header>Haljine</Menu.Header>

                    <Menu.Menu>
                        <Link to="/shop/Haljina_sa_mašnama" onClick={this.handleItemClick} className='item'> Haljina sa mašnama </Link>
                        <Link to="/shop/Haljina_sa_karnerima" onClick={this.handleItemClick} className='item'> Haljina sa karnerima </Link>
                    </Menu.Menu>
                </Menu.Item>
            </React.Fragment>
        )
    }
}

export default connect((state) => { return {}; }, { closeSidebar })(ProductsSidebar);