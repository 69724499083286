import React from 'react';

import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { emptyCart } from '../../actions';

class OrderForm extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
        };

    constructor(props) {
        super(props);

        const { cookies } = props;
        this.state = {
            trackingKey: cookies.get('trackingKey')
        };
    }

    renderError({ touched, error }) {
        if (touched && error)
            return <div className='ui pointing red basic label'> {error} </div>
        return null;
    }

    renderInputField = ({ input, label, meta, placeholder }) => {
        return (
            <div className='field'>
                <label> {label} </label>
                <input {...input} placeholder={placeholder}/>
                {this.renderError(meta)}
            </div>
        );
    }

    onSubmit = async (formValues) => {

        document.getElementById('submitOrder').disabled = true

        const res = await axios.post('/orders', {
            cart: this.props.cart,
            recipient: formValues
        });

        const { cookies } = this.props;

        cookies.set('trackingKey', res.data, { path: '/' });
        this.setState({ trackingKey: res.data });

        this.props.emptyCart();
        this.props.history.push('/orders/tracking/' + res.data);
    }

    render() {
        if (!this.props.cart.length)
            return;

        return (
            <form className='ui form' onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className='header'>
                    <h4>
                        Podaci za isporuku
                    </h4>
                    <br />
                </div>
                <div className='field'>
                    <div className='two fields'>
                        <Field name='fullName' component={this.renderInputField} label='Ime i prezime' required placeholder='Milena Milutinović'/>
                        <Field name='username' component={this.renderInputField} label='Korisničko ime sa Instagrama' placeholder='milena120'/>
                    </div>
                </div>
                <div className='field'>
                    <div className='two fields'>
                        <Field name='email' component={this.renderInputField} label='E-mail' placeholder='milena@gmail.com'/>
                        <Field name='phone' component={this.renderInputField} label='Kontakt telefon' placeholder='065 12 34 567'/>
                    </div>
                </div>

                <Field name='address' component={this.renderInputField} label='Adresa za isporuku (ulica, broj, opština, poštanski broj, grad)' placeholder='Sarajevska 10, Savski venac, 11000 Beograd'/>
                <Field name='note' component={this.renderInputField} label='Napomene' placeholder=''/>

                <button className="ui button primary" type='submit' id='submitOrder'> Poruči </button>
            </form>
        );
    }
};

const mapStateToProps = (state) => {
    if (state.auth.user)
        return {
            initialValues: {
                fullName: state.auth.user.name,
                email: state.auth.user.email
            },
            cart: state.cart
        };
    else
        return {
            initialValues: {
                fullName: '',
                email: ''
            },
            cart: state.cart
        };
}

const validate = (formValues) => {
    let errors = {};
    if (!formValues.fullName)
        errors.fullName = 'Neophodno je uneti ime i prezime primaoca.';

    if (!formValues.email)
        errors.email = 'Neophodno je uneti ispravnu e-mail adresu.';

    if (!formValues.phone)
        errors.phone = 'Neophodno je uneti ispravan broj telefona.';

    if (!formValues.address)
        errors.address = 'Neophodno je uneti adresu isporuke.';

    return errors;
}

export default connect(mapStateToProps, {emptyCart})(reduxForm({ form: 'makeOrder', enableReinitialize: true, validate })(withCookies(withRouter(OrderForm))));