import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Footer from './parts/Footer';
import Header from './parts/Header';
import LandingPage from './LandingPage';
import ModelPage from './ModelPage';
import ShoppingCartPage from './ShoppingCartPage';
import ShoppingSuccessPage from './ShoppingSuccessPage';
import { Sidebar, Menu, Label } from 'semantic-ui-react';
import { closeSidebar } from '../actions';
import { connect } from 'react-redux';
import MenuExampleHeaderVertical from './parts/Sidebar';
import OrdersPage from './OrdersPage';
import NotFoundPage from './NotFoundPage';

class App extends React.Component {

    render() {
        return (

            <div className='main-container'>
                <BrowserRouter>

                    <Sidebar.Pushable raised>
                        <Sidebar
                            as={Menu}
                            innerRef={this.segmentRef}
                            onHide={() => this.props.closeSidebar()}
                            vertical
                            target={this.segmentRef}
                            visible={this.props.isOpen}
                            animation='push'
                        >
                            <Label attached='top right' className='close-label' onClick={() => this.props.closeSidebar()}> <i className='ui icon close'></i></Label>
                            <MenuExampleHeaderVertical />
                        </Sidebar>
                        <div className=''>

                            <Header />
                            <Switch>
                                <Route path='/' exact component={LandingPage} />
                                <Route path='/shop/:modelName' exact component={ModelPage} />
                                <Route path='/cart' component={ShoppingCartPage} />
                                <Route path='/orders/tracking/:trackingKey' exact component={ShoppingSuccessPage} />
                                <Route path='/pracenje' component={OrdersPage} exact />
                                <Route component={NotFoundPage} />
                            </Switch>
                        </div>
                    </Sidebar.Pushable>
                </BrowserRouter>
                <Footer />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { isOpen: state.isSidebarOpen };
}

export default connect(mapStateToProps, { closeSidebar })(App);