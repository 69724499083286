import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import ProductDetails from './parts/ProductDetails';
import { Dropdown } from 'semantic-ui-react'


/*
    /shop/:modelName
*/

class ModelPage extends React.Component {
    state = {
        products: [],
        currentProduct: null,
        patternTypes: []
    }

    async fetchData() {
        let modelName = this.props.match.params.modelName;
        const res = await axios.get(`/products/${modelName.replaceAll('_', ' ')}`);
        let pt = _.uniq(res.data.map(item => item.patternTypeName));
        this.setState({ products: res.data, currentProduct: res.data[0], patternTypes: pt });
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.match.params.modelName !== prevProps.match.params.modelName) {
            await this.fetchData();
        }
    }

    renderPatternTypes() {
        return this.state.patternTypes.map(patternType => {
            return (
                <React.Fragment>
                    <Dropdown.Header content={`${patternType}`} />
                    {this.renderProducts(patternType)}
                </React.Fragment>
            );
        });
    }

    renderProducts(patternType) {
        return this.state.products.map(p => {
            if (p.patternTypeName === patternType)
                return (<Dropdown.Item key={p.name} text={p.name} value={p.name} image={{ avatar: true, src: `../${p.images[0]}` }} onClick={() => this.setState({ currentProduct: p })} />);
            else
                return null;
        });
    }

    render() {
        return (
            <div className='ui container white-box'>
                <h3 class="ui header">
                    {this.props.match.params.modelName.replaceAll('_', ' ')}
                </h3>
                <div className='ui stackable grid'>
                    <div className='four wide column' style={{ 'paddingTop': '2em' }}>
                        <h3 class="ui header">Dezeni</h3>
                        <Dropdown
                            text={this.state.currentProduct ? this.state.currentProduct.name : 'Izaberite dezen'}
                            fluid
                            selection
                        >
                            <Dropdown.Menu>
                                {this.renderPatternTypes()}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='twelve wide column'>
                        <ProductDetails product={this.state.currentProduct} />
                    </div>
                </div>
            </div>
        );
    }
};

export default ModelPage;