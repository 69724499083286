export const cartReducer = (state = [], action) => {
    switch (action.type) {
        case 'ADD_TO_CART':
            let {id, imgUrl, name, price} = action.payload.product;
            let cart_id = state.length ? Math.max(state.map(el => el.cart_id))+1 : 0;
            return [...state, { cart_id, id, imgUrl, name, price, ...action.payload.formValues }]
        case 'DELETE_FROM_CART':
            const p = action.payload;
            const newState = state.filter(item => {
                return item.cart_id !== p.cart_id;
            });
            return newState;
        case 'EMPTY_CART':
            return [];
        default:
            return state;
    }
}