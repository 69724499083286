import React from 'react';
import axios from 'axios';

class OrdersPage extends React.Component {

    state = {
        orders: [],
        status: 0,
        employees: []
    }

    async componentDidMount() {
        let isAdmin = false;

        let checkAuth = await axios.get('/api/checkLogin');
        isAdmin = checkAuth.data.isAdmin;
        if(!isAdmin) {
            let secret = window.prompt('Enter secret: ');
            let auth = await axios.post('/api/login', {
                secret: secret
            });
            isAdmin = auth.data.isAdmin
        }

        if (isAdmin) {
            let res = await axios.get('/api/orders');
            this.setState({ orders: res.data });

            let employees = await axios.get('/api/employees');
            this.setState({ employees: employees.data });
        } else {
            window.alert('Refresh and try again.');
        }
    }

    renderOrders() {
        return this.state.orders.map(order => {
            if (order.status !== this.state.status)
                return null;

            let empOptions = null;
            if (this.state.employees)
                empOptions = this.state.employees.map(emp => {
                    return (
                        <option value={emp.id}> {emp.name} </option>
                    );
                });

            let items = order.items.map(item => {
                let emp = this.state.employees.filter(emp => emp.id === item.employeeId);
                emp = emp.length ? emp[0] : null;
                console.log(item);
                return (
                    <tr>
                        <td>
                            {order.id}/{item.id}
                        </td>
                        <td colSpan='3'>
                            <h4 className="ui image header">
                                <img src={item.imgUrl} className="ui mini rounded image" alt='thumb' />
                                <div className="content"> {item.name}
                                    <div className="sub header">
                                        Velicina: {item.size}
                                        {item.size === 'po meri' && item.customSize &&
                                            <span>
                                                {item.customSize.length && <span> <br />Dužina: {item.customSize.length}</span>}
                                                {item.customSize.suit_length && <span> <br />Dužina sakoa: {item.customSize.suit_length}</span>}
                                                {item.customSize.pants_length && <span> <br />Dužina pantalona: {item.customSize.pants_length}</span>}
                                                {item.customSize.chest && <span> <br />Obim grudi: {item.customSize.chest}</span>}
                                                {item.customSize.weist && <span> <br />Obim struka: {item.customSize.weist}</span>}
                                                {item.customSize.hips && <span> <br />Obim kukova: {item.customSize.hips}</span>}
                                                {item.customSize.arms_length && <span> <br />Dužina rukava: {item.customSize.arms_length}</span>}
                                                {item.customSize.shoulder_width && <span> <br />Širina ramena: {item.customSize.shoulder_width}</span>}
                                            </span>
                                        }
                                    </div>
                                    {item.note && <div className="sub header"> Napomena: {item.note}</div>}
                                </div>
                            </h4>
                        </td>
                        <td>
                            Snajderka: {emp ? emp.name : 'Nije dodeljena'}
                            {
                                <form>
                                    <div class="two fields">
                                        <div class="field">
                                            <select class="ui fluid search dropdown" value={item.employeeId}>
                                                <option value='0'> Izaberi snajderku </option>
                                                {empOptions}
                                            </select>
                                        </div>
                                        <div class="field">
                                            <div className='ui button'> Dodeli </div>
                                        </div>
                                    </div>
                                </form>
                            }
                        </td>
                    </tr>
                );
            });

            let createdAt = new Date(order.createdAt);
            return (
                <React.Fragment>
                    <tr className='order-row'>
                        <td> {order.id} </td>
                        <td> {createdAt.toDateString()} <br /> {createdAt.toLocaleTimeString()} </td>
                        <td>
                            {order.recipientName} <br />
                            {order.recipientEmail} <br />
                            {order.recipientAddress} <br />
                            {order.recipientPhone} <br />
                            IG: {order.recipientIg}
                        </td>
                        <td> {order.items.reduce((sum, item) => sum + item.price, 0)} </td>
                        <td>
                            {
                                order.status === 0 &&
                                <button className='ui button' onClick={() => this.changeStatus(order.id, 1)}> Posalji na izradu </button>
                            }
                            {
                                order.status === 1 &&
                                <div className="ui buttons">
                                    <button className='ui button' onClick={() => this.changeStatus(order.id, 0)}> Vrati na cekanje </button>
                                    <button className='ui button' onClick={() => this.changeStatus(order.id, 2)}> Spakuj </button>
                                </div>
                            }
                            {
                                order.status === 2 &&
                                <div className="ui buttons">
                                    <button className='ui button' onClick={() => this.changeStatus(order.id, 1)}> Vrati na izradu </button>
                                    <button className='ui button' onClick={() => this.changeStatus(order.id, 3)}> Posalji </button>
                                </div>
                            }
                            {
                                order.status === 3 &&
                                <div className="ui buttons">
                                    <button className='ui button' onClick={() => this.changeStatus(order.id, 3)}> Vrati na spakovano </button>
                                    Broj pošiljke: {order.shippingNumber}
                                </div>
                            }
                        </td>
                    </tr>
                    {items}
                </React.Fragment>
            );
        });
    }

    changeStatus = async (orderId, status) => {
        await axios.patch(`/api/orders/${orderId}`, {
            status: status
        });

        let orders_copy = [...this.state.orders];
        let order_idx = this.state.orders.findIndex(o => o.id === orderId);
        orders_copy[order_idx].status = status;

        this.setState({
            orders: orders_copy
        });
    }

    render() {
        return (
            <div className='ui container white-box'>
                <div className='ui form'>
                    <div className="four fields">
                        <div className='field'>
                            <div className="ui labeled button w100" onClick={() => this.setState({ status: 0 })}>
                                <div className="ui teal button w100"> Nove porudzbine </div>
                                <a className="ui basic teal left pointing label"> {this.state.orders.filter(item => item.status === 0).length} </a>
                            </div>
                        </div>

                        <div className='field'>

                            <div className="field ui labeled button w100" onClick={() => this.setState({ status: 1 })}>
                                <div className="ui teal button" style={{ 'width': '100%' }}> U izradi </div>
                                <a className="ui basic teal left pointing label"> {this.state.orders.filter(item => item.status === 1).length} </a>
                            </div>
                        </div>

                        <div className='field'>

                            <div className="field ui labeled button w100" onClick={() => this.setState({ status: 2 })}>
                                <div className="ui teal button" style={{ 'width': '100%' }}> Spakovane </div>
                                <a className="ui basic teal left pointing label"> {this.state.orders.filter(item => item.status === 2).length} </a>
                            </div>
                        </div>

                        <div className='field'>

                            <div className="field ui labeled button w100" onClick={() => this.setState({ status: 3 })}>
                                <div className="ui teal button" style={{ 'width': '100%' }}> Poslate </div>
                                <a className="ui basic teal left pointing label"> {this.state.orders.filter(item => item.status === 3).length} </a>
                            </div>
                        </div>
                    </div>
                </div>
                <table className='ui celled compact table orders-table'>
                    <thead>
                        <tr>
                            <th> Broj </th>
                            <th> Datum </th>
                            <th> Narucilac </th>
                            <th> Iznos </th>
                            <th> Izmeni status </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderOrders()}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default OrdersPage;