import axios from "axios"

export const addProductToCart = (product, formValues) => {
    return {
        type: 'ADD_TO_CART',
        payload: { product, formValues }
    }
}

export const emptyCart = () => {
    return {
        type: 'EMPTY_CART',
        payload: null
    }
}

export const deleteProductFromCart = (product) => {
    return {
        type: 'DELETE_FROM_CART',
        payload: product
    }
}

export const submitCart = (cart) => {
    return {
        type: 'SUBMIT_CART',
        payload: cart
    }
}

export const _getLoginStatus = userId => async dispatch => {
    var payload = await new Promise((resolve, fail) => {
        window.FB.getLoginStatus((res) => resolve(res));
    });

    dispatch({
        type: 'GET_LOGIN_STATUS',
        payload: payload
    });
}

export const _login = () => async dispatch => {
    var payload = await new Promise((resolve, fail) => {
        window.FB.login((res) => resolve(res), { scope: 'public_profile,email' });
    });
    dispatch({
        type: 'LOGIN',
        payload: payload
    });
}

export const login = () => async (dispatch, getState) => {

    await dispatch(_getLoginStatus());

    if (getState().auth.status !== 'connected') {
        await dispatch(_login());
    }

    var payload = await new Promise((resolve, fail) => {
        window.FB.api('/me', { fields: 'name, email' }, (res) => resolve(res));
    })

    dispatch({
        type: 'GET_USER_DATA',
        payload: payload
    });
}

export const getProducts = () => async(dispatch, getState) => {
    const res = await axios.get('/products');

    dispatch({
        type: 'GET_PRODUCT_LIST',
        payload: res.data
    });
}

export const getProductDetails = (productId) => async(dispatch, getState) => {
    let p = getState().products.find(el => el.id === productId);
    if(!p) {
        let res = await axios.get(`/products/${productId}`);
        p = res.data;
    }
    dispatch({
        type: 'GET_PRODUCT_DETAILS',
        payload: p
    });
}

export const openSidebar = () => {
    return {
        type: 'OPEN_SIDEBAR',
        payload: null
    }
}

export const closeSidebar = () => {
    return {
        type: 'CLOSE_SIDEBAR',
        payload: null
    }
}