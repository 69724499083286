import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                Tema by Tamara Perošević &copy; 2021 <br/>
                U slučaju bilo kakvih pitanja, možete nam pisati na našem 
                <a href='https://www.instagram.com/temafashion.rs/' target='_blank'> Instagram nalogu </a>
            </div>
        );
    }
}

export default Footer;