import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../header.css';
import { openSidebar } from '../../actions';

class Header extends React.Component {

  render() {
    return (
      // <header className="black-80 tc pv4 avenir">
      //   <img src='http://localhost:3000/tema.jpg' class="br-100 pa1 ba b--black-10 h3 w3" style={{ 'max-width': '7em' }} alt="avatar" />

      //   <nav className="tc mw7 center mt4">

      //     <Link className="f6 f5-l link bg-animate black-80 dib pa3 ph4-l" to="/">
      //       Početna
      //     </Link>

      //     <a className="f6 f5-l link bg-animate black-80 dib pa3 ph4-l" onClick={() => this.props.openSidebar()} href='#'> Svi proizvodi </a>

      //     <Link className="f6 f5-l link bg-animate black-80 dib pa3 ph4-l" to='/cart'>
      //       <i className="shopping cart icon"></i> Korpa
      //       <label className="ui circular mini label">{this.props.cart.length}</label>
      //     </Link>
      //   </nav>
      // </header>
      <div className="ui menu">
        <a className="item" onClick={() => this.props.openSidebar()} href='#'> Svi proizvodi </a>
        <div className='center menu'>
          <Link to='/' className='item'>
            <div className='logo-container'>
              <span className='tema'> tema </span>
              <span className='bytamara'> by Tamara Perošević </span>
            </div>
          </Link>
        </div>
        <div className="right menu">
          <Link className="item" to='/cart'>
            <i className="shopping cart icon"></i> Korpa
            <label className="ui circular mini label">{this.props.cart.length}</label>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { cart: state.cart, isOpen: state.isSidebarOpen };
}

export default connect(mapStateToProps, { openSidebar })(Header);