import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

class ImageCarousel extends React.Component {
    constructor(props) {
        super(props);

        this.primaryRef = React.createRef();
        this.secondaryRef = React.createRef();
    }

    componentDidMount() {
        // Set the sync target right after the component is mounted.
        this.primaryRef.current.sync(this.secondaryRef.current.splide);
    }

    componentDidUpdate() {
        this.primaryRef.current.remount();
        this.secondaryRef.current.remount();
        this.primaryRef.current.sync(this.secondaryRef.current.splide);
    }

    renderImages() {
        if (this.props.images)
            return this.props.images.map(img => {
                return (
                    <SplideSlide key={img}>
                        <img src={`../../${img}`} alt="Product" />
                    </SplideSlide>
                );
            })
    }

    renderVideos() {
        if (this.props.videos)
            return this.props.videos.map(video => {
                return (
                    <SplideSlide key={video}>
                        <video muted='muted' autoplay='autoplay' loop='loop' src={`../../${video}`} type="video/mp4">
                        </video>
                    </SplideSlide>
                );
            })
    }

    renderThumbForVideos() {
        if (this.props.videos)
            return this.props.videos.map(video => {
                return (
                    <SplideSlide key={video}> 
                        <div style={{ 'backgroundColor': 'red' }}>
                            VIDEO
                        </div>
                    </SplideSlide>
                );
            })
    }

    render() {
        const primaryOptions = {
            type: 'loop',
            width: 800,
            perPage: 1,
            perMove: 1,
            gap: '1rem',
            pagination: false,
            // maxHeight: 600,
            start: 0
        };

        const secondaryOptions = {
            type: 'slide',
            rewind: true,
            width: 800,
            gap: '.5rem',
            pagination: false,
            fixedWidth: 40,
            fixedHeight: 60,
            cover: true,
            focus: 'center',
            isNavigation: true,
            updateOnMove: true,
            start: 0,
            arrows: false
        };

        return (
            <div>
                <Splide options={primaryOptions} ref={this.primaryRef}>
                    {this.renderImages()}
                    {this.renderVideos()}
                </Splide>

                <Splide options={secondaryOptions} ref={this.secondaryRef}>
                    {this.renderImages()}
                    {this.renderThumbForVideos()}
                </Splide>
            </div>
        );
    }
}

export default ImageCarousel;