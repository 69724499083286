import { combineReducers } from "redux";

import { reducer } from 'redux-form';

import { productListReducer } from "./productListReducer";
import { cartReducer } from "./cartReducer";
import { authReducer } from './authReducer';
import { utilsReducer } from "./utilsReducer";
import { sidebarReducer } from "./sidebarReducer";

export default combineReducers({
    auth: authReducer,
    products: productListReducer,
    cart: cartReducer,
    utils: utilsReducer,
    form: reducer,
    isSidebarOpen: sidebarReducer
});