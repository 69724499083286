import React from 'react';

class NotFoundPage extends React.Component {
    render() {
        return (
            <div className="">
                Stranica ne postojiiiiii
            </div>
        );
    }
}

export default NotFoundPage;