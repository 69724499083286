import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addProductToCart, getProductDetails } from '../../actions';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import ImageCarousel from '../helpers/ImageCarousel';

class ProductDetails extends React.Component {
    onSubmit = (formValues) => {
        if (!formValues.note)
            formValues.note = '';
        this.props.addProductToCart(this.props.product, formValues);
        document.querySelector('#successDiv').style.display = 'block';
        document.querySelector('.addToCartButtons').style.display = 'none';
    }

    renderCarusel() {
        return (
            <ImageCarousel images={this.props.product.images} videos={this.props.product.videos} />
        );
    }

    renderError({ touched, error }) {
        if (touched && error) {
            return (
                <div className='ui pointing red basic label'>
                    {error}
                </div>
            );
        }
    }

    renderSize = ({ input, meta }) => {

        let sizesOptions = []
        for (const key in this.props.product.defaultSizes) {
            sizesOptions.push(key);
        }

        let options = sizesOptions.map(s => {
            return <option value={`${s}`}>{s}</option>;
        })

        return (
            <div className="field">
                <label>Velicina</label>
                <select {...input} >
                    <option value=""> Izaberite velicinu</option>
                    {options}
                </select>
                {this.renderError(meta)}
            </div>
        );
    }

    renderNote = ({ input, meta }) => {
        return (
            <div className="field">
                <label> Napomena </label>
                <textarea rows="2" placeholder='' {...input}></textarea>
                {this.renderError(meta)}
            </div>
        );
    }

    renderCustomSize = ({ input, meta, label }) => {
        return (
            <div className="field">
                <label> {label} </label>
                {/* <input type='number' {...input} /> */}
                <div class="ui right labeled input">
                    <input type="number" {...input} className='custom-size-input'/>
                    <div class="ui basic label">
                        cm
                    </div>
                </div>
                {this.renderError(meta)}
            </div>
        );
    }

    renderCustomSizeFields = (defaultSizes) => {
        const names = {
            'Obim grudi': 'chest',
            'Dužina': 'length',
            'Obim struka': 'weist',
            'Obim kukova': 'hips',
            'Širina ramena': 'shoulder_width',
            'Dužina rukava': 'arm_length',
            'Dužina sakoa': 'suit_length',
            'Dužina pantalona': 'pants_length'
        };

        let fields = [];

        for (let key in defaultSizes) {
            fields.push(
                <Field name={`customSize.${names[key]}`} label={key} component={this.renderCustomSize} validate={[required]} />
            );
        }
        return fields;
    }

    renderForm(product, size) {
        return (
            <form className="ui form add-to-cart-form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className='fields'>
                    <div className='field'>
                        <Field name='size' component={this.renderSize} />
                    </div>
                </div>

                {size && size === 'po meri' && <div className='custom-sizes-container'>
                    {this.renderCustomSizeFields(product.defaultSizes['po meri'])}
                </div>}

                <div className='bottom aligned'>
                    <Field name='note' component={this.renderNote} />
                    <div className='two ui buttons addToCartButtons'>
                        {
                            product.amount != 0
                                ?
                                <button className="ui animated fade button" type='submit'>
                                    <div className="visible content"><i className='ui icon shopping cart' /></div>
                                    <div className="hidden content"> Dodaj u korpu </div>
                                </button>
                                :
                                <button disabled className='ui button'> Proizvod trenutno nije raspoloživ. </button>
                        }
                    </div>
                </div>

                {this.renderSuccessFields()}
            </form>
        );
    }

    renderSuccessFields() {
        return (
            <div className='ui segment brown' style={{ display: 'none' }} id='successDiv'>
                <h4 className='header' style={{ textAlign: 'center' }}> Uspesno ste dodali proizvod u korpu</h4>
                <div className='two ui buttons'>
                    <Link to='/cart' className='ui button primary'> Idi na porucivanje </Link>
                    <Link to='/' className='ui button'> Nastavi kupovinu </Link>
                </div>
            </div>
        );
    }

    renderSizeTableRow(firstCell, values) {
        let cells = [];
        for (let key in values)
            cells.push(<td>{values[key]}</td>);

        return (
            <tr>
                {firstCell}
                {cells}
            </tr>
        );
    }

    renderSizeTableHeader(values) {
        let cells = [];
        for (let key in values)
            cells.push(<th>{key}</th>);

        return (
            <tr>
                <th></th>
                {cells}
            </tr>
        );
    }

    renderSizeTable() {
        let defaultSizes = this.props.product.defaultSizes;
        let rows = [];
        let header = null;
        for (let key in defaultSizes) {
            if (key === 'po meri')
                continue;

            let valuesForSize = defaultSizes[key];

            if (!header)
                header = this.renderSizeTableHeader(valuesForSize)

            rows.push(this.renderSizeTableRow((<th> {key} </th>), valuesForSize));
        }

        return (
            <table class="ui compact collapsing celled unstackable table">
                <thead>
                    {header}
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }

    render() {
        const { product, size } = this.props;

        if (!this.props.product)
            return null;

        return (
            <div>
                <div className="ui celled stackable grid product details">
                    <div className="product image ten wide column">
                        {this.renderCarusel()}
                    </div>
                    <div className="six wide column">
                        <h3 className="ui header">{product.name}
                        </h3>
                        {product.amount < 5 && product.amount != 0 &&
                            <span className="ui label stock-label">
                                <i class="ui bell outline icon"></i> Raspoloživo: {product.amount}
                            </span>
                        }
                        {product.amount == 0 &&
                            <span className="ui label red">
                                <i class="ui bell outline icon"></i> Dezen trenutno nije raspoloživ.
                            </span>
                        }
                        <h2 className="ui sub header">
                            Materijal
                        </h2>
                        <span> {product.fabric} </span>
                        <h2 className="ui sub header">
                            Cena
                        </h2>
                        <span> {product.price} din</span>


                        <div className="ui divider"></div>
                        {this.renderForm(product, size)}
                    </div>
                </div>

                <div className='ui segment'>
                    <p className='product-description'>
                        <h2 className="ui sub header">
                            Napomene
                        </h2>
                        {product.description}
                    </p>
                    <p>
                        <h2 className="ui sub header">
                            Tabela velicina
                        </h2>
                        {this.renderSizeTable()}
                    </p>
                </div>

            </div>
        );
    }
}

const validate = (formValues) => {
    const errors = {};
    if (!formValues.size)
        errors.size = 'Izaberite velicinu';

    return errors;
}

const required = value => (value ? undefined : 'Obavezno polje.');

const selector = formValueSelector('productDetails');

const mapStateToProps = (state, props) => {
    return {
        cart: state.cart,
        size: selector(state, 'size')
    };
}

export default connect(mapStateToProps, { addProductToCart, getProductDetails })(reduxForm({ form: 'productDetails', validate })(ProductDetails));