export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_LOGIN_STATUS':
            return {...state, auth: action.payload};
        case 'LOGIN':
            return {...state, auth: action.payload};
        case 'GET_USER_DATA':
            return {...state, user: action.payload};
        default:
            return state;
    }
}