import _ from 'lodash';

export const productListReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_PRODUCT_LIST':
            return action.payload;
        case 'GET_PRODUCT_DETAILS':
            return _.uniq([...state, action.payload]);
        default:
            return state;
    }
}