import React from 'react';
import axios from 'axios';

/*
    /orders/tracking/:trackingKey
*/

class ShoppingSuccessPage extends React.Component {

    state = {
        order: null
    };

    async componentDidMount() {
        window.scrollTo(0, 0);
        let trackingKey = this.props.match.params.trackingKey;
        let { data } = await axios.get(`/orders/${trackingKey}`);
        this.setState({ order: data });
    }

    getContentDependOnStatus = () => {
        let title = '';
        let subtitle = '';
        let icon = '';
        if (this.state.order) {
            switch (parseInt(this.state.order.status)) {
                case 0:
                    title = 'Hvala na ukazanom poverenju!';
                    subtitle = 'Uspešno ste poslali porudžbinu.';
                    icon = 'check';
                    break;
                case 1:
                    title = 'Vaša porudžbina se upravo izradjuje.';
                    subtitle = 'Vreme izrade je 1-2 dana.';
                    icon = 'cut';
                    break;
                case 2:
                    title = 'Vaša porudžbina je spakovana za slanje.'
                    subtitle = 'Očekujte da će biti poslata u toku sutrašnjeg dana.';
                    icon = 'box';
                    break;
                case 3:
                    title = 'Vaša pošiljke je poslata.';
                    subtitle = 'Broj pošiljke (PostExpress): ' + this.state.order.shippingNumber;
                    icon = 'shipping fast';
                    break;
            }
        }

        return { title, subtitle, icon };
    }

    renderItems() {
        if (this.state.order)
            return this.state.order.items.map(item => {
                return (
                    <tr key={item.id}>
                        <td>
                            <h4 className="ui image header">
                                <img src={item.imgUrl.replace('.', '')} className="ui mini rounded image" alt='thumb' />
                                <div className="content"> {item.name}
                                    <div className="sub header">
                                        Velicina: {item.size}
                                        {item.size === 'po meri' && item.customSize &&
                                            <span>
                                                {item.customSize.length && <span> <br />Dužina: {item.customSize.length}</span>}
                                                {item.customSize.chest && <span> <br />Obim grudi: {item.customSize.chest}</span>}
                                                {item.customSize.weist && <span> <br />Obim struka: {item.customSize.weist}</span>}
                                                {item.customSize.hips && <span> <br />Obim kukova: {item.customSize.hips}</span>}
                                                {item.customSize.arms_length && <span> <br />Dužina rukava: {item.customSize.arms_length}</span>}
                                                {item.customSize.shoulder_width && <span> <br />Širina ramena: {item.customSize.shoulder_width}</span>}
                                                {item.customSize.suit_length && <span> <br />Dužina sakoa: {item.customSize.suit_length}</span>}
                                                {item.customSize.pants_length && <span> <br />Dužina pantalona: {item.customSize.pants_length}</span>}
                                            </span>
                                        }
                                    </div>
                                    {item.note && <div className="sub header"> Napomena: {item.note}</div>}
                                </div>
                            </h4>
                        </td>
                        <td className='right aligned'>{item.price}din</td>
                    </tr>
                );
            });

        return null;
    }

    render() {
        let { order } = this.state;
        let statusData = this.getContentDependOnStatus();
        let step3Class = order && order.status > 0 ? 'active' : '';
        let step4Class = order && order.status > 1 ? 'active' : '';
        let step5Class = order && order.status > 2 ? 'active' : '';

        return (
            <div className='ui container white-box'>
                <h3 className='ui center aligned header'> {statusData.title}</h3>
                <h4 className="sub header tc">
                    {statusData.subtitle}
                </h4>
                <h2 className="ui center aligned icon header">
                    <i className={`tracking-icon circular icon ${statusData.icon}`}></i>
                </h2>

                <div className="ui five steps inverted">
                    <div className="active step">
                        <i className="shopping bag icon"></i>
                        <div className="content">
                            <div className="title">Poručeno</div>
                        </div>
                    </div>
                    <div className="active step">
                        <i className="check icon"></i>
                        <div className="content">
                            <div className="title">Čeka na izradu</div>
                            <div className="description"> Prosečno vreme čekanja na izradu je 2-7 dana. </div>
                        </div>
                    </div>
                    <div className={`step ${step3Class}`}>
                        <i className="cut icon"></i>
                        <div className="content">
                            <div className="title">U izradi</div>
                        </div>
                    </div>
                    <div className={`step ${step4Class}`}>
                        <i className="box icon"></i>
                        <div className="content">
                            <div className="title">Spakovano</div>
                            <div className="description"> </div>
                        </div>
                    </div>
                    <div className={`step ${step5Class}`}>
                        <i className="shipping fast icon"></i>
                        <div className="content">
                            <div className="title">Poslato</div>
                            <div className="description">
                                {order && order.shippingNumber && `Broj porudžbine (PostExpress): ${order.shippingNumber}`}
                            </div>
                        </div>
                    </div>
                </div>

                <h4 className="ui sub header">
                    Sadržaj porudžbine
                </h4>
                {order && <table class="ui celled table unstackable">
                    <thead>
                        <tr>
                            <th>Proizvod</th>
                            <th className='right aligned'>Cena</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderItems()}
                    </tbody>
                </table>
                }
                <h4 className="ui sub header">
                    Detalji porudžbine
                </h4>
                {order && <table class="ui compact collapsing table">
                    <tbody>
                        <tr>
                            <th> Kreirana: </th>
                            <td> {order.createdAt}</td>
                        </tr>
                        <tr>
                            <th> Primalac: </th>
                            <td> {order.recipientName}</td>
                        </tr>
                        <tr>
                            <th> Adresa: </th>
                            <td> {order.recipientAddress}</td>
                        </tr>
                        <tr>
                            <th> Broj telefona: </th>
                            <td> {order.recipientPhone}</td>
                        </tr>
                        <tr>
                            <th> E-mail: </th>
                            <td>{order.recipientEmail}</td>
                        </tr>
                    </tbody>
                </table>
                }
                <h4 className="ui sub header tc">
                    Ovde možete pratiti promenu statusa porudžbine. Link na ovu stranicu Vam je poslat na e-mail adresu koju ste uneli pri poručivanju.
                </h4>
            </div>
        );
    }
}

export default ShoppingSuccessPage;