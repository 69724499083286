import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { openSidebar } from '../actions';

class LandingPage extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className='grid-container'>
                    <div className='grid-item welcome-container'>
                        <div className='welcome-div'>
                            <div class="">
                                <div class="content">
                                    <h3> Dobrodošli! </h3>
                                    <span style={{ 'fontSize': '1rem' }}>
                                        Ovo je Web-kutak modnog brenda koji je nastao sa ciljem da se jedna žena zabavi i radi ono što najviše voli. <br />
                                        <br />
                                        Čuda postoje ako veruješ u njih. <br />
                                        Tema by Tamara Perošević
                                    </span>
                                </div>
                                <div class="extra content" style={{ 'marginTop': '30px' }}>
                                    <button class="ui black button" onClick={() => { this.props.openSidebar() }}>Ponuda</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='grid-item right'>
                        <img src='background.png' width='80%' className='' />

                    </div>
                </div>

                <div className='bg-white'>
                    <div class="ui basic segment serif container">

                        <h2 className='landing-page-title'> Proizvodi</h2>
                        <h4 className='tc landing-page-subtitle'>
                            Pre nego što pogledate ponudu, važno je da znate da je nama udobnost i nosivost iznad svih kriterijuma.
                            Ovde nećete pronaći komade koji zauvek ostaju na ofingerima nakon jednog nošenja.
                            Važno je da budete - Tema.
                            Ali mnogo puta.
                        </h4>

                        <div class="ui three column very relaxed grid">
                            <div class="column">
                                <Link to='/shop/New_York_style_odelo' class="ui large image">
                                    <img class="" src="./img/models/new_york_style_odelo.jpg" alt="odelo" />
                                </Link>
                                <h2 className="tc landing-product-title"> New York style odelo </h2>
                                <Link to='/shop/New_York_style_odelo'>
                                    <button className='ui black button tc landing-black-button'> Detalji </button>
                                </Link>

                            </div>

                            <div class="column">
                                <Link to='/shop/Tema_trenerka' class="ui large image">
                                    <img class="" src="./img/models/tema_trenerka.jpg" alt="trenerka" />
                                </Link>
                                <h2 className="tc landing-product-title"> Tema trenerka </h2>
                                <Link to='/shop/Tema_trenerka'>
                                    <button className='ui black button tc landing-black-button'> Detalji </button>
                                </Link>

                            </div>

                            <div class="column">
                                <Link to='/shop/Strukirani_jednobojni_mantil' class="ui large image">
                                    <img class="" src="./img/models/strukirani_mantil.jpg" alt="mantil" />
                                </Link>
                                <h2 className="tc landing-product-title"> Strukirani jednobojni mantil </h2>
                                <Link to='/shop/Strukirani_jednobojni_mantil'>
                                    <button className='ui black button tc landing-black-button'> Detalji </button>
                                </Link>

                            </div>

                        </div>

                        <div class="ui three column very relaxed grid">

                            <div class="column">
                                <Link to='/shop/Mantil_u_tri_boje' class="ui large image">
                                    <img class="" src="./img/models/mantilitto.jpg" alt="Mantilitto" />
                                </Link>
                                <h2 className="tc landing-product-title"> Mantil u tri boje </h2>
                                <Link to='/shop/Mantil_u_tri_boje'>
                                    <button className='ui black button tc landing-black-button'> Detalji </button>
                                </Link>

                            </div>

                            <div class="column">
                                <Link to='/shop/Dnevno_odelo' class="ui large image">
                                    <img class="" src="./img/models/odelo.jpg" alt="Odelo" />
                                    {/* <img className='sold-out' src='/img/soldout.png' alt='sold out' /> */}
                                </Link>
                                <h2 className="tc landing-product-title"> Dnevno odelo </h2>
                                <Link to='/shop/Dnevno_odelo'>
                                    <button className='ui black button tc landing-black-button'> Detalji </button>
                                </Link>

                            </div>

                            <div class="column">
                                <Link to="/shop/Oversized_košulja" class="ui large image">
                                    <img class="" src="./img/models/cosuljitta.jpeg" alt="Cosuljitta" />
                                </Link>
                                <h2 className="tc landing-product-title"> Oversized košulja </h2>

                                <Link to='/shop/Oversized_košulja'>
                                    <button className='ui black button tc landing-black-button'> Detalji </button>
                                </Link>

                            </div>

                        </div>
                        <div class="ui three column very relaxed grid next-row">


                            <div class="column">
                                <Link to="/shop/Elegantna_haljina_sa_mašnama" class="ui large image">
                                    <img class="" src="./img/models/mashnitta_elegance.jpg" alt="Mashnitta Elegance" />
                                </Link>
                                <h2 className="tc landing-product-title"> Elegantna haljina sa mašnama </h2>
                                <Link to='/shop/Elegantna_haljina_sa_mašnama'>
                                    <button className='ui black button tc landing-black-button'> Detalji </button>
                                </Link>
                            </div>

                            <div class="column">
                                <Link to='/shop/Haljina_sa_karnerima' class="ui large image">
                                    <img class="" src="./img/models/carneritta.jpg" alt="" />
                                </Link>
                                <h2 className="tc landing-product-title"> Haljina sa karnerima </h2>
                                <Link to='/shop/Haljina_sa_karnerima'>
                                    <button className='ui black button tc landing-black-button'> Detalji </button>
                                </Link>

                            </div>
                            <div class="column">
                                <Link to="/shop/Haljina_sa_mašnama" class="ui large image">
                                    <img class="" src="./img/models/mashnitta.jpeg" alt="" />
                                </Link>
                                <h2 className="tc landing-product-title"> Haljina sa mašnama </h2>
                                <Link to='/shop/Haljina_sa_mašnama'>
                                    <button className='ui black button tc landing-black-button'> Detalji </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return { cart: state.cart, isOpen: state.isSidebarOpen };
}

export default connect(mapStateToProps, { openSidebar })(LandingPage);

