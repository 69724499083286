import React from 'react';
import { connect } from 'react-redux';
import { deleteProductFromCart } from '../actions';

import OrderForm from './parts/OrderForm';

/*
    /cart
*/

class ShoppingCartPage extends React.Component {

    state = {
        showModal: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderItems() {
        if (this.props.cart)
            return this.props.cart.map(item => {
                return (
                    <tr key={item.id}>
                        <td>
                            <h4 className="ui image header">
                                <img src={item.imgUrl} className="ui mini rounded image" alt='thumb' />
                                <div className="content"> {item.name}
                                    <div className="sub header">
                                        Velicina: {item.size}
                                        {item.size === 'po meri' && item.customSize &&
                                            <span>
                                                {item.customSize.length && <span> <br />Dužina: {item.customSize.length}</span>}
                                                {item.customSize.chest && <span> <br />Obim grudi: {item.customSize.chest}</span>}
                                                {item.customSize.weist && <span> <br />Obim struka: {item.customSize.weist}</span>}
                                                {item.customSize.hips && <span> <br />Obim kukova: {item.customSize.hips}</span>}
                                                {item.customSize.arms_length && <span> <br />Dužina rukava: {item.customSize.arms_length}</span>}
                                                {item.customSize.shoulder_width && <span> <br />Širina ramena: {item.customSize.shoulder_width}</span>}
                                                {item.customSize.suit_length && <span> <br />Dužina sakoa: {item.customSize.suit_length}</span>}
                                                {item.customSize.pants_length && <span> <br />Dužina pantalona: {item.customSize.pants_length}</span>}
                                            </span>
                                        }
                                    </div>
                                    {item.note && <div className="sub header"> Napomena: {item.note}</div>}
                                </div>
                            </h4>
                        </td>
                        <td className='right aligned'>{item.price}din</td>
                        <td className='collapsing'><i className='ui icon delete' onClick={() => { this.props.deleteProductFromCart(item) }}></i></td>
                    </tr>
                );
            });

        return null;
    }

    render() {

        if (!this.props.cart.length) {
            return (
                <div className='ui container' style={{ 'minHeight': '90vh' }}>
                    <h3> Vasa korpa je trenutno prazna. </h3>
                </div>
            );
        }

        return (
            <div className='ui container shopping-cart white-box'>
                <h3 style={{ textAlign: 'center' }}> Porudzbina </h3> <br />
                <table className="ui celled table unstackable">
                    <thead>
                        <tr>
                            <th>Proizvod</th>
                            <th className='right aligned'>Cena</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderItems()}
                    </tbody>
                    <tfoot className="full-width">
                        <tr>
                            <th colspan="2" className='right aligned'>
                                <h4 className='ui brown label large'>Za plaćanje: {this.props.priceToPay} din + poštarina</h4>
                            </th>
                            <th></th>
                        </tr>
                    </tfoot>
                </table>
                <div className='ui segment'>
                    <p>
                        <h4> Informacije o procesu poručivanja </h4>
                        <ul>
                            <li> Plaćanje robe se vrši isključivo pouzećem. </li>
                            <li> Pre poručivanja proverite da li ste izabrali ispravnu veličinu. </li>
                            <li> <b> Vodite računa da pri poručivanju unesete ispravne podatke za isporuku. </b> </li>
                            <li> Nakon poručivanja, na email će Vam stici potvrda porudžbine i link putem kog možete da pratite status Vaše porudzbine. </li>
                        </ul>
                    </p>
                </div>
                <div className='ui segment'>
                    <OrderForm />
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const priceToPay = state.cart.reduce((sum, item) => sum + item.price, 0);
    return { cart: state.cart, priceToPay }
}

export default connect(mapStateToProps, { deleteProductFromCart })(ShoppingCartPage);